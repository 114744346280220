body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 


  Designed by Clinx GmbH, 2014

   
*/

/* =Last update:
-----------------------------------------------------------------------------*/
.footerdate:after {
    content: '22. Januar 2020';
}

/* =Typography
-----------------------------------------------------------------------------*/

::selection {
    background: #ad2222;
    color: #ffffff;
}

::-moz-selection {
    background: #ad2222;
    color: #ffffff;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('./fonts/OpenSans-Light.ttf') format('truetype'),
        url('./fonts/OpenSans-Light.eot') format('embedded-opentype'), url('./fonts/OpenSans-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./fonts/OpenSans-Regular.ttf') format('truetype'),
        url('./fonts/OpenSans-Regular.eot') format('embedded-opentype'),
        url('./fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('./fonts/OpenSans-Bold.ttf') format('truetype'),
        url('./fonts/OpenSans-Bold.eot') format('embedded-opentype'), url('./fonts/OpenSans-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
}

body {
    color: #e2ecfc;
    font-family: 'OpenSans-Regular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
    background-color: #6e83a2;
}

b,
strong {
    font-family: 'OpenSans-Bold', sans-serif;
    font-weight: normal;
    letter-spacing: 0.2pt;
}

.home {
    background-image: url(./images/bg.gif);
    background-repeat: repeat-x;
}

#wrapper_content {
    background-image: url(./images/bg_1920x1080.jpg);
    background-repeat: no-repeat;
    background-position: center 60px;
    background-attachment: fixed;
    background-size: 100%;
    background-color: #0b1a30;
    /*21395b*/
}

#wrapper_grafik {
    background-image: url(./images/bg.gif);
    background-repeat: repeat-x;
    background-position: top;
    margin: 0 auto;
}

#content_rahmen {
    width: 980px;
    margin: 0 auto;
}

#content {
    width: 960px;
    margin: 0 auto;
}

a {
    color: #fc7761;
    /*#f9553a*/
    text-decoration: none;
}

a:hover {
    color: #ffbeb3;
    /*#ff9482*/
    text-decoration: underline;
}

h1,
h1 a {
    font-family: 'OpenSans-Regular', sans-serif;
    color: #f9553a !important;
    font-size: 36px;
    line-height: 39px;
    font-weight: normal;
    margin: 0px 0px 15px 0px;
    padding-top: 18px;
}

h1 a:hover {
    color: #f9553a !important;
    text-decoration: none;
}

h2,
h2 a {
    font-family: 'OpenSans-Regular', sans-serif;
    color: #f9553a !important;
    font-size: 30px;
    line-height: 31px;
    font-weight: normal;
    margin: 0px 0px 25px 0px;
}

h2 a:hover {
    color: #f9553a !important;
    text-decoration: none;
}

h3 {
    color: #fff;
    font-size: 19px;
    font-weight: normal;
    margin: 25px 0px 15px 0px;
}

h4 {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin: 15px 0px 5px 0px;
}

h5 {
    color: #9cb0cc;
    font-size: 13px;
    font-weight: normal;
    margin: 5px 0px 5px 0px;
}

.legendentext {
    padding-top: 5px;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 15px;
    font-style: italic;
    color: #9cb0cc;
}

hr {
    color: #405a7b;
    background-color: #405a7b;
    height: 3px;
    border: 0;
    margin: 25px 0;
}

.color1 {
    color: #9cb0cc;
    /*hellblau*/
}

.color1.bold {
    font-family: 'OpenSans-Bold', sans-serif;
    font-weight: normal;
    letter-spacing: 0.2pt;
}

.color2 {
    color: #f9553a;
    /*rot*/
}

.color3 {
    color: #fff;
    /*weiss*/
}

ul {
    list-style-image: url('./images/icon_bullet.png');
    list-style-position: outside;
}

blockquote {
    font-family: 'OpenSans-Light', sans-serif;
    background-image: url('./images/icon_blockquote.png');
    background-position: 25px 4px;
    background-repeat: no-repeat;
    color: #9abbe7;
    letter-spacing: 0.4;
    margin-left: 0px;
    padding-left: 55px;
    padding-right: 20px;
    font-style: italic;
    font-size: 16px;
    line-height: 23px;
    border-right: 1px solid #405a7b;
    max-width: 500px;
}

.quelle {
    font-size: 13px;
    font-weight: bold;
}

/* --------------------------- */
/* HEADER */

#header_1 {
    height: 200px;
    width: 960px;
    float: left;
    background-image: url(./images/header_1.png);
    background-repeat: no-repeat;
    background-position: -15px 0;
    margin: 0 auto;
}

#header_2 {
    height: 34px;
    width: 960px;
    float: left;
    margin: 0 auto;
}

#header_3 {
    height: 300px;
    width: 960px;
    float: left;
    margin: 0 auto;
    display: none;
}

#header_4 {
    height: 15px;
    width: 960px;
    float: left;
    margin: 0 auto;
    display: none;
}

#header_5 {
    display: none;
    height: 25px;
    width: 960px;
    float: left;
    margin: 0 auto;
    padding-top: 10px;
    background-color: #ff9900;
}

#header_print {
    display: none;
}

#header_3,
#header_4 {
}

/* --------------------------- */
/* LOGO LINK Home */

#logo_link {
    position: absolute;
    margin-left: 0px;
    margin-top: 0px;
    clear: right;
    text-decoration: none;
}

/* --------------------------- */
/* MENUTOP */

#menutop {
    position: absolute;
    display: block;
    float: left;
    margin-left: 0px;
    margin-top: 0px;
    width: 960px;
    height: 34px;
    text-align: justify;
}

.menutop_main {
    height: 38px;
    float: left;
}

.menutop_main a {
    float: left;
    color: #b2cbf3;
    text-decoration: none;
    display: block;
    padding: 7px 10px 6px;
    font-size: 15px;
    font-weight: normal;
    background-repeat: repeat-x;
    background-position: top left;
    border-top: none;
    border-bottom: none;
}

.menutop_main a:hover {
    color: #fff;
    background-color: #5b81c0;
}

.menutop_main_act {
    float: left;
    color: #fff;
    text-decoration: none;
    display: block;
    font-size: 15px;
    font-weight: normal;
    border-top: none;
    border-bottom: none;
    background-color: #5b81c0;
}

.menutop_main a.active {
    float: left;
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 7px 10px 6px;
    font-size: 15px;
    font-weight: normal;
    border-top: none;
    border-bottom: none;
    background-color: #5b81c0;
}

.menutop_main a.active:hover {
    color: #fff;
    background-repeat: repeat-x;
    background-position: top left;
}

/* --------------------------- */
/* TOPNAVI Home Contact Facebook */

#topnavi_home {
    position: absolute;
    margin-left: 859px;
    margin-top: 120px;
}

#topnavi_home a {
    height: 28px;
    width: 28px;
    display: block;
    background: url(./images/topnavi_home.png) no-repeat;
}

#topnavi_home a:hover {
    background: url(./images/topnavi_home_r.png) no-repeat;
}

#topnavi_mail {
    position: absolute;
    margin-left: 893px;
    margin-top: 120px;
}

#topnavi_mail a {
    height: 28px;
    width: 28px;
    display: block;
    background: url(./images/topnavi_mail.png) no-repeat;
}

#topnavi_mail a:hover {
    background: url(./images/topnavi_mail_r.png) no-repeat;
}

#topnavi_facebook {
    position: absolute;
    margin-left: 927px;
    margin-top: 120px;
}

#topnavi_facebook a {
    height: 28px;
    width: 28px;
    display: block;
    background: url(./images/topnavi_facebook.png) no-repeat;
}

#topnavi_facebook a:hover {
    background: url(./images/topnavi_facebook_r.png) no-repeat;
}

#login {
    color: black;
    position: absolute;
    right: 10px;
    top: 10px;
}

#login a {
    color: black;
}

#login a:hover {
    color: red;
}

/* --------------------------------------------------------------------------------*/
/* CONTENT */

#maincontent {
    float: left;
    width: 100%;
    margin-top: 25px;
}

#maincontent_home {
    float: left;
    width: 958px;
    margin-top: 0px;
}

#maincontent_new {
    float: left;
    width: 925px;
    margin-top: 0px;
    margin-left: 30px;
}

#main_home_left {
    clear: left;
    margin-top: 0px;
    padding-left: 0px;
    float: left;
    width: 320px;
    text-align: left;
    padding: 0px 20px 0px 22px;
}

#main_home_right {
    margin-top: 0px;
    padding-left: 0px;
    float: right;
    width: 596px;
    text-align: left;
}

#mainwide {
    margin-top: 0px;
    padding-left: 0px;
    float: left;
    width: 100%;
    text-align: left;
}

#mainnarrow {
    margin-top: 0px;
    padding-left: 0px;
    float: left;
    width: 670px;
    text-align: left;
}

#mainwide ul,
#mainnarrow ul,
#main_home_left ul,
#main_home_right ul,
#mainnew ul,
#dialog-modal ul,
#rightcolumn ul {
    margin: 18px 0 10px 30px;
}

.mainwide a,
.mainnarrow a,
.main_home_left a,
.main_home_right a,
.mainnew a,
#dialog-modal a {
    color: #da8a00;
    text-decoration: underline;
}

.mainwide a:hover,
.mainnarrow a:hover,
.main_home_left a:hover,
.main_home_right a:hover,
.mainnew a:hover,
#dialog-modal a:hover {
    color: #9c3e00;
    text-decoration: underline;
}

.mainnarrow img a:hover {
    background: none;
}

.contenttable {
    color: #222;
    background: #ff0000;
    border-collapse: collapse;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid #cfcfcf;
}

.frame_1 {
    background-image: url(./images/bg_konzerte_box.png);
    background-repeat: no-repeat;
    background-position: top right;
    color: #fff;
    padding: 20px 20px 22px;
    background-color: #2a578e;
    border-bottom: 3px solid #8ba2c2;
    border-radius: 5px;
}

.frame_1.ausverkauft {
    background-image: url(./images/bg_konzerte_box_ausverkauft.png);
    background-color: #a32636;
    border-bottom: 3px solid #e56c7b;
}

.frame_2 {
    background-image: url(./images/bg_konzerte_box.png);
    background-repeat: no-repeat;
    background-position: top right;
    color: #fff;
    padding: 15px 15px 17px;
    background-color: #0d2f4e;
    border-bottom: 3px solid #b23722;
    border-radius: 5px;
    margin-top: 6px;
}

.frame_hyperlinks .hyperlinks {
    padding-top: 8px;
    padding-bottom: 9px;
}

.frame_hyperlinks .hyperlinks a {
    text-decoration: none;
    color: #fff;
}

.first_frame {
    margin-top: 0;
}

.frame_2:hover {
    background-color: #113657;
}

.konzert {
    padding-top: 5px;
}

.konzert.first_konzert {
    padding-top: 0px;
}

/* TABLE */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

table.width-100 {
    border-top: 1px solid #405a7b;
}

.width-100 {
    width: 100%;
}

.width-70 {
    width: 70%;
}

.width-60 {
    width: 60%;
}

.width-50 {
    width: 50%;
}

.width-40 {
    width: 40%;
}

.width-30 {
    width: 30%;
}

th,
td {
    border-bottom: 1px solid #405a7b;
    padding: 5px 5px 6px;
}

tr:hover {
    background-color: #21395b;
}

/* --------------------------------------------------------------------------------*/
/* RIGHT COLUMN */

#rightcolumn {
    float: right;
    width: 265px;
    clear: right;
}

.rightcolumn {
}

.right_content {
    background-image: url(./images/bg_right.png);
    background-repeat: repeat-y;
    padding: 5px 0 1px 25px;
    margin-bottom: 0px;
    border-radius: 6px;
}

#rightcolumn h1 {
    padding-top: 18px;
    line-height: 31px;
    font-size: 30px;
}

#rightcolumn h2 {
    padding-top: 18px;
    line-height: 24px;
}

/* --------------------------- */
/* FOOTER */

#wrapper_footer {
    clear: both;
    width: 100%;
    border-top: 2px solid #d3e0ed;
}

#footer {
    margin: 0 auto;
    padding-top: 0px;
    width: 960px;
    height: 325px;
    letter-spacing: 0.3pt;
}

#footer p {
    color: #09264f;
    font-size: 13px;
}

#footer a {
    color: #d3e0ed;
    text-decoration: none;
}

#footer a:hover {
    color: #fff;
}

#footer a.to-top:hover {
}

#left {
    float: left;
    width: 250px;
    padding-top: 50px;
}

#right {
    float: left;
    width: 420px;
    padding-top: 50px;
    padding-left: 0px;
    margin-left: 0px;
}

#right_home {
    float: left;
    width: 371px;
    padding-top: 0px;
    padding-left: 95px;
    margin-left: 0px;
}

#right .right_1,
#right_home .right_1 {
    clear: left;
    padding-bottom: 15px;
}

#right .right_2,
#right_home .right_2 {
    clear: right;
}

#right .right_3,
#right_home .right_3 {
}

#notice_paper {
    float: right;
}

#to_top_home,
#to_top {
    float: right;
    padding-top: 0px;
    padding-bottom: 55px;
}

.social_footer {
    float: left;
    width: 280px;
    padding-top: 50px;
    padding-left: 0px;
    margin-left: 0px;
}

.facebook_footer {
    margin-left: 0px;
    margin-top: 0px;
}

.facebook_footer a {
    height: 53px;
    width: 120px;
    display: block;
    background: url(./images/facebook_footer.png) no-repeat;
}

.facebook_footer a:hover {
    background: url(./images/facebook_footer_r.png) no-repeat;
}

/* --------------------------- */
/* IMAGES */

.image_wrapper {
    float: left;
    margin: 0 20px 20px 0;
}

.text_image_left {
    padding-left: 185px;
}

.image_border {
    border-bottom: 3px solid #8ba2c2;
    border-radius: 5px;
}

/* IMAGES VERGROESSERN */

div.imageHold {
    padding: 10px 10px;
    /* damit der container die h�he des gro�en bildes annimmt */
    /* andere formatierung, z.B. zentrieren/etc: */
    /* .... */
    z-index: 1;
}

div.imageHold div {
    float: left;
    width: 210px;
    height: 142px;
    /* ab hier kann man die abst�nde
  und sonstiges der bilder eintragen */
    margin-left: 0px;
}

div.imageHold img {
    width: 210px;
    /* wir skalieren das gro�e bild auf die kleine gr��e */
    height: auto;
    /* um verpixelung beim verg��ern zu verhindern       */
}

div.imageHold img:hover--- {
    position: absolute;
    margin-left: -85px;
    /* die h�lfte des gr��enunterschiedes der bilder */
    margin-top: -55px;
    /* hier genau so */
    width: 800px;
    /* die weite beim vergr��ern */
    height: 542px;
    /* die h�he beim vergr��ern */
}

div.imageHold img:hover {
    position: inherit;
    margin-left: -10px;
    /* die h�lfte des gr��enunterschiedes der bilder */
    margin-top: -10px;
    /* hier genau so */
    width: 600px;
    /* die weite beim vergr��ern */
    height: 407px;
    /* die h�he beim vergr��ern */
    float: right;
}

.paragraph {
    margin-top: 20px;
}

.environmentMarker {
    display: block;
    position: absolute;
    opacity: 0.6;
    top: 30px;
    left: -88px;
    width: 300px;
    height: 80px;
    padding: 5px;
    -ms-transform: rotate(-45deg); /* IE9 */
    -moz-transform: rotate(-45deg); /* FF3.5/3.6 */
    -o-transform: rotate(-45deg); /* Opera 10.5 */
    -webkit-transform: rotate(-45deg); /* Saf3.1+ */
    transform: rotate(-45deg); /* Newer browsers */
    /*filter: progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476); */ /* IE6,IE7, but produces nasty results, so I disabled it */
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(SizingMethod='auto expand', M11=0.7071067811865476, M12=-0.7071067811865475, M21=0.7071067811865475, M22=0.7071067811865476)"; /* IE8 */
}
