.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.ticket-link:hover {
    text-decoration: none;
    color: white;
}

.ticket-link {
    text-decoration: none;
    color: white;
}

.concert-container.hovered {
    background-color: #333333;
    border: 1px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.icon-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 5em;
    line-height: 4em;
    vertical-align: middle;
}
.icon-stack-1x,
.icon-stack-2x,
.icon-stack-3x {
    position: absolute;
    left: 0;
    width: 100%;
}
.icon-stack-1x {
    line-height: inherit;
    position: absolute;
    left: 8px;
    top: -11px;
}
.icon-stack-2x {
    font-size: 1.5em;
}
.icon-stack-3x {
    font-size: 2em;
}

.reservation-cancel:hover {
    color: red;
}

.seat {
    margin: 0;
    &.reserved {
        color: red;
    }
    &.desired {
        color: green;
        &:hover {
            color: yellow;
            opacity: 0.4;
        }
    }
    &.free {
        color: yellow;
        &:hover {
            color: green;
            opacity: 0.8;
        }
    }
    &.blocked {
        color: lightgray;
    }
}

.plan-container {
    text-align: center;
    width: 100%;
    height: 100%;
    border: none;

    &.dev {
        .plan-grid {
            border: 2px solid white;
            .plan-grid-row {
                border: 2px solid white;
                .plan-grid-block {
                    border: 2px solid white;
                }
            }
        }
    }

    .plan-grid {
        margin: 0 auto;
        align-self: center;
        background-color: none;
        background: none;
        border: none;
        background-color: none;

        .plan-grid-row {
            border: none;
            &:hover {
                background-color: none;
                background: none;
            }
            .plan-grid-block {
                border: none;
                justify-content: center;
                &:hover {
                    background-color: none;
                }
                &.stage {
                    min-width: 10px;
                    min-height: 40px;
                    margin: 2px;
                }
                &.mixer {
                    min-width: 10px;
                    min-height: 40px;
                    margin: 2px;
                }
                &.entrance {
                    min-width: 10px;
                    min-height: 40px;
                    margin: 2px;
                }
                &.empty {
                    min-width: 10px;
                    min-height: 40px;
                    margin: 2px;
                }
                &.seats {
                    background: none;
                    .seats-block {
                        background: none;
                        text-align: center;
                        .seats-block-title-row {
                            background: none;
                            &:hover {
                                background: none;
                            }
                            .seats-block-title {
                                text-align: left;
                                background: none;
                                &:hover {
                                    background: none;
                                }
                            }
                        }

                        .seats-row {
                            &:hover {
                            }
                            .seat-cell {
                                &.empty {
                                }
                            }
                            .row-label {
                                &:hover {
                                }
                            }
                        }

                        .seats-label-row {
                            background: none;
                            &:hover {
                            }
                            .seat-label {
                                font-weight: bold;
                            }
                        }

                        .corridor-row {
                            background: none;
                            .corridor-cell {
                                justify-content: center;
                                min-width: 10px;
                                min-height: 10px;
                                margin: 2px;
                            }
                        }
                    }
                }
                &.empty {
                    justify-content: center;
                }
            }
        }
    }
}
